<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Food Merchant</h4>
    </vs-row>

    <vs-row vs-w="6">
      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Category</span>
        <v-select
          label="name"
          :options="featureCategoryOptions"
          :reduce="(category) => category.id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.feature_category_id"
        />
      </vs-col>
      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          label="Merchant Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>
      <vs-col vs-w="12" class="mb-3">
        <p class="text-sm">Description (*)</p>
        <vs-textarea class="w-full" v-model="payload.description" />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("description")
        }}</span>
      </vs-col>
      <vs-col vs-w="6">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="300" height="300" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("logo")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
// Store Module
import foodMerchantStore from "@/store/food-merchant";

export default {
  metaInfo: {
    title: "Add Food Merchant",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    vSelect,
  },

  data: () => ({
    payload: {
      name: "",
      description: "",
      feature_category_id: "",
      logo: null,
    },
    url: null,
    featureCategoryOptions: [],
  }),

  methods: {
    getFeatureCategories() {
      axios
        .get("/vancynet/food-merchant-category")
        .then(({ data: res }) => {
          this.featureCategoryOptions = res.data.map((category) => {
            return {
              id: category.id,
              name: category.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },
    async storeData() {
      const formData = new FormData();
      formData.set("name", this.payload.name);
      formData.set(
        "food_merchant_category_id",
        this.payload.feature_category_id
      );
      formData.set("description", this.payload.description);
      formData.set("logo", this.payload.logo);
      try {
        const response = await this.$store.dispatch(
          "foodMerchant/storeFoodMerchant",
          formData
        );
        if (!response.success) {
          throw error(response.message);
        }
        this.$vs.notify({
          title: "Successful",
          text: response.msg_status,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
        });

        this.$router.push("/food-merchant");
      } catch (error) {
        console.log(error);
        this.$catchErrorResponse(error);
      }
    },
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.logo = file;
    },
    removeImage() {
      this.url = null;
      this.payload.logo = "";
    },
  },

  created() {
    if (!foodMerchantStore.isRegistered) {
      this.$store.registerModule("foodMerchant", foodMerchantStore);
      foodMerchantStore.isRegistered = true;
    }
    this.getFeatureCategories();
  },
};
</script>

<style></style>
